<template>
  <div id="questionsView">
    <!--  搜索框表单  -->
    <a-form class="search" :model="searchParas" layout="inline">
      <a-form-item field="title" label="标题" style="min-width: 280px">
        <a-input v-model="searchParas.title" placeholder="请输入题目名称" />
      </a-form-item>
      <a-form-item field="tags" label="标签" style="min-width: 280px">
        <a-input-tag v-model="searchParas.tags" placeholder="请输入标签" />
      </a-form-item>
      <a-form-item>
        <a-button type="primary" @click="doSearch">搜索</a-button>
      </a-form-item>
    </a-form>
    <!--  题目列表  -->
    <a-table
      :columns="columns"
      :data="dataList"
      :pagination="{
        showTotal: true,
        pageSize: searchParas.pageSize,
        current: searchParas.current,
        total,
      }"
      @page-change="onPageChange"
      style="margin: 0 20px"
    >
      <!--   题目标签   -->
      <template #tags="{ record }">
        <a-space wrap>
          <a-tag
            v-for="(tag, index) of record.tags"
            :key="index"
            color="green"
            >{{ tag }}</a-tag
          >
        </a-space>
      </template>
      <template #ACRate="{ record }">
        <!--  提交数（分母）不为零
         格式： 通过率%（通过数/提交数）
         parseFloat().toFixed(4) 四舍五入 后四位 -->
        {{
          `${
            parseFloat(
              record.submitNum ? record.acceptedNum / record.submitNum : "0"
            ).toFixed(4) * 100
          }% (${record.acceptedNum}/${record.submitNum})`
        }}
      </template>
      <template #createTime="{ record }">
        {{ moment(record.createTime).format("YYYY-MM-DD") }}
      </template>
      <template #optional="{ record }">
        <a-space>
          <a-button type="primary" @click="toQuestionPage(record)"
            >查看</a-button
          >
        </a-space>
      </template>
    </a-table>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, watchEffect } from "vue";
import {
  Question,
  QuestionControllerService,
  QuestionQueryRequest,
} from "../../../generated";
import message from "@arco-design/web-vue/es/message";
import { useRouter } from "vue-router";
import moment from "moment";

const router = useRouter();

const show = ref(true);

const dataList = ref([]);
const total = ref(0);
const searchParas = ref<QuestionQueryRequest>({
  // 查询条件
  title: "",
  tags: [],
  pageSize: 10, // 每页题目数
  current: 1,
});

/**
 *  分页获取 题目数据
 */
const loadData = async () => {
  const res = await QuestionControllerService.listQuestionVoByPageUsingPost(
    searchParas.value
  );
  console.log(res.data);
  // 查询结果为0
  if (res.code === 0) {
    dataList.value = res.data.records; // 数据条数
    total.value = res.data.total; // 数据总数
  } else {
    message.error("加载失败，" + res.message);
  }
};

/**
 *  页面加载时 请求数据
 */
onMounted(() => {
  loadData();
});

const columns = [
  {
    title: "题号",
    dataIndex: "id",
  },
  {
    title: "标题",
    dataIndex: "title",
  },
  {
    title: "标签",
    slotName: "tags",
  },
  {
    title: "通过率",
    slotName: "ACRate",
  },
  {
    title: "创建时间",
    slotName: "createTime",
  },
  {
    title: "操作",
    slotName: "optional",
  },
];

/**
 * 监听函数loadData
 *  若函数中有变量改变，程序执行该函数
 *   用于分页，当searchParas改变时，重新加载页面
 */
watchEffect(() => {
  loadData();
});

// 分页时更新页号
const onPageChange = (page: number) => {
  searchParas.value = {
    ...searchParas.value,
    current: page,
  };
};

/**
 *  跳转题目详情页
 * @param question
 */
const toQuestionPage = (question: Question) => {
  // 路由跳转 get传id
  router.push({
    path: `/detail/question/${question.id}`,
  });
};

/**
 *  题目模糊搜索
 *    搜索是对search修改，提交时 触发自动刷新
 */
const doSearch = () => {
  searchParas.value = {
    ...searchParas.value,
    current: 1, // 页号恢复到第一页
  };
};
</script>

<style scoped>
#questionsView {
  max-width: 1280px;
  margin: 0 auto;
}

.search {
  margin: 15px 50px;
}
</style>

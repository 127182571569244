import ACCESS_ENUMS from "@/access/accessEnum";

/**
 *  全局权限管理 -- 检验方法
 * @param loginUser 当前登录用户
 * @param needAccess 需要也有的权限(为空 默认未登录)
 * @return boolean 有无权限
 */
const checkAccess = (loginUser: any, needAccess = ACCESS_ENUMS.NOT_LOGIN) => {
  // 获取当前用户 权限(若为空默认 未登录)
  const loginUserAccess = loginUser?.userRole ?? ACCESS_ENUMS.NOT_LOGIN; // ?防止为空出现报错    ??定义默认值

  if (needAccess === ACCESS_ENUMS.NOT_LOGIN) {
    // 无需权限
    return true; // 直接放行
  }
  // todo 普通用户权限 需要登录
  if (needAccess === ACCESS_ENUMS.USER) {
    if (loginUserAccess === ACCESS_ENUMS.NOT_LOGIN) {
      // 用户未登录
      return false; // 无权限
    }
  }

  // todo 需要管理员权限
  if (needAccess === ACCESS_ENUMS.ADMIN) {
    if (loginUserAccess !== ACCESS_ENUMS.ADMIN) {
      // 用户未登录
      return false; // 无权限
    }
  }
  // console.log("admin用户登录  ！");
  return true;
};

export default checkAccess;

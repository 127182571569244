/**
 *  全局权限
 */
const ACCESS_ENUMS = {
  NOT_LOGIN: "notLogtin",
  USER: "user",
  ADMIN: "admin",
};

export default ACCESS_ENUMS; // 导出元素 才能被其他元素引用

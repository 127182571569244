<template>
  <!-- 该元素用于 monaco-editor 定位 -->
  <div
    id="coe-editor"
    ref="codeEditorRef"
    style="min-height: 500px; height: 70vh"
  />
  <!--  <a-button @click="fillValue">填充值</a-button>-->
</template>

<script setup lang="ts">
import * as monaco from "monaco-editor";
import { defineProps, onMounted, ref, toRaw, watch, withDefaults } from "vue";
import CodeEditor from "@/components/CodeEditor.vue";

const codeEditorRef = ref(); // id为coe-editor 的元素
const codeEditor = ref();
const value = ref("Hello World");

/**
 *  定义组件属性类型
 */
interface Props {
  value: string;
  language: string;
  handleChange: (v: string) => void;
}

// 给需要父组件彻底的元素 设置初始值
const props = withDefaults(defineProps<Props>(), {
  value: () => "",
  language: () => "java",
  handleChange: (v: string) => {
    console.log(v); // 每输入一个字符 响应
  },
});

/**
 * 监听 props 中语言值变化（父组件修改编程语言）
 *    触发编译器修改语言
 */
watch(
  () => props.language,
  () => {
    console.log(monaco.languages.getLanguages());
    monaco.editor.setModelLanguage(
      // 踩坑一定要使用toRaw
      toRaw(codeEditor.value).getModel(),
      props.language
    );
  }
);

onMounted(() => {
  if (!codeEditorRef.value) {
    return;
  }

  // 初始化 编辑器
  codeEditor.value = monaco.editor.create(codeEditorRef.value, {
    value: props.value,
    language: props.language,
    automaticLayout: true, // 自适应大小
    minimap: {
      enabled: true,
    },
    fontSize: 20,
    readOnly: false,
    theme: "github", // 主题
  });

  // 实时监听 编辑器内容变化
  codeEditor.value.onDidChangeModelContent(() => {
    // console.log("代码编辑器内容：", toRaw(codeEditor.value).getValue());
    props.handleChange(toRaw(codeEditor.value).getValue());
  });
});
</script>

<style></style>

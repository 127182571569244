<template>
  <div id="manageQuestionView">
    <a-table
      :columns="columns"
      :data="dataList"
      :pagination="{
        showTotal: true,
        pageSize: searchParas.pageSize,
        current: searchParas.current,
        total,
      }"
      @page-change="onPageChange"
      style="margin: 0 20px"
    >
      <template #createTime="{ record }">
        {{ moment(record.createTime).format("YYYY-MM-DD") }}
      </template>
      <!--   两个按钮   -->
      <template #optional="{ record }">
        <a-space>
          <a-button type="primary" @click="doUpdate(record)">修改</a-button>
          <a-button status="danger" @click="doDelete(record)">删除</a-button>
        </a-space>
      </template>
    </a-table>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, watchEffect } from "vue";
import { Question, QuestionControllerService } from "../../../generated";
import message from "@arco-design/web-vue/es/message";
import { useRouter } from "vue-router";
import moment from "moment";

const router = useRouter();

const show = ref(true);

const dataList = ref([]);
const total = ref(0);
const searchParas = ref({
  // 查询条件
  pageSize: 4,
  current: 1,
});

/**
 *  分页获取 题目数据
 */
const loadData = async () => {
  const res = await QuestionControllerService.listQuestionByPageUsingPost(
    searchParas.value
  );
  console.log(res.data);
  // 查询结果为0
  if (res.code === 0) {
    dataList.value = res.data.records; // 数据条数
    total.value = res.data.total; // 数据总数
  } else {
    message.error("加载失败，" + res.message);
  }
};

/**
 *  页面加载时 请求数据
 */
onMounted(() => {
  loadData();
});

const columns = [
  {
    title: "id",
    dataIndex: "id",
  },
  {
    title: "标题",
    dataIndex: "title",
  },
  {
    title: "内容",
    dataIndex: "content",
  },
  {
    title: "标签",
    dataIndex: "tags",
  },
  {
    title: "答案",
    dataIndex: "answer",
  },
  {
    title: "提交数",
    dataIndex: "submitNum",
  },
  {
    title: "通过数",
    dataIndex: "acceptedNum",
  },
  {
    title: "判题配置",
    dataIndex: "judgeConfig",
  },
  {
    title: "判题用例",
    dataIndex: "judgeCase",
  },
  {
    title: "创建用户id",
    dataIndex: "userId",
  },
  {
    title: "创建时间",
    slotName: "createTime",
  },
  {
    title: "操作",
    slotName: "optional",
  },
];

/**
 * 监听函数loadData
 *  若函数中有变量改变，程序执行该函数
 *   用于分页，当searchParas改变时，重新加载页面
 */
watchEffect(() => {
  loadData();
});

// 分页时更新页号
const onPageChange = (page: number) => {
  searchParas.value = {
    ...searchParas.value,
    current: page,
  };
};

// 删除按钮
const doDelete = async (question: Question) => {
  const res = await QuestionControllerService.deleteQuestionUsingPost({
    id: question.id,
  });
  if (res === 0) {
    message.success("删除成功");
    // todo 删除成功 更新数据
    loadData();
  } else {
    message.error("删除失败，请重试");
  }
};

// 修改按钮
const doUpdate = (question: Question) => {
  // 路由跳转 get传id
  router.push({
    path: "/update/question",
    query: {
      id: question.id,
    },
  });
};
</script>

<style scoped>
#manageQuestionView {
}
</style>

<template>
  <div id="userlayout">
    <a-layout style="min-height: 100vh">
      <a-layout-header class="header">
        <a-space>
          <img src="../assets/logo.png" class="logo" />
          <div style="font-size: 18px">CxCS OJ</div>
        </a-space>
      </a-layout-header>
      <a-layout-content class="content">
        <!--  引入路由布局 指向的文件内容  -->
        <router-view />
      </a-layout-content>
      <a-layout-footer class="footer">CxCS-OJ by cxcs</a-layout-footer>
    </a-layout>
  </div>
</template>

<style>
#userlayout {
  text-align: center;
}

#userlayout .logo {
  width: 64px;
  height: 64px;
}

#userlayout .header {
  margin-top: 16px;
  margin-bottom: 16px;
  box-shadow: #eee 1px 1px 5px;
}

#userlayout .content {
  margin-bottom: 16px;
}

#userlayout .footer {
  font-size: 16px;
  position: sticky;
  bottom: 0;
  right: 0;
  left: 0;
  text-align: center;
}
</style>
<script setup lang="ts"></script>

<template>
  <div id="questionDetailView">
    <a-row :gutter="[24, 24]">
      <a-col :md="12" :xs="24">
        <!--  分页栏 菜单  -->
        <a-tabs default-active-key="question">
          <a-tab-pane key="question" title="题目">
            <a-card v-if="question">
              <!--    标题     -->
              <template #title>
                <div
                  style="
                    color: #595959;
                    font-weight: bolder;
                    font-size: 24px;
                    font-family: '楷体';
                  "
                >
                  {{ question.title }}
                </div>
              </template>
              <!--   判题信息   -->
              <a-descriptions
                title="判题条件"
                :column="{ xs: 1, md: 2, lg: 3 }"
              >
                <a-descriptions-item label="时间限制">
                  {{ question.judgeConfig.timeLimit ?? 0 }}
                </a-descriptions-item>
                <a-descriptions-item label="内存限制">
                  {{ question.judgeConfig.memoryLimit ?? 0 }}
                </a-descriptions-item>
                <a-descriptions-item label="堆栈限制">
                  {{ question.judgeConfig.stackLimit ?? 0 }}
                </a-descriptions-item>
              </a-descriptions>
              <!--   题目内容 使用markdown    -->
              <MdViewer :value="question.content" />
              <!--   左侧标签   -->
              <template #extra>
                <a-space wrap>
                  <a-tag
                    v-for="(tag, index) of question.tags"
                    :key="index"
                    color="green"
                    >{{ tag }}</a-tag
                  >
                </a-space>
              </template>
            </a-card>
          </a-tab-pane>
          <a-tab-pane key="record" title="提交记录">
            <template #title>提交记录</template>
            <a-table :columns="columns" :data="dataList" style="margin: 0 20px">
              <!--  运行状态 0 - 等待中、1 - 运行中、2 - 成功、3 - 失败 -->
              <template #status="{ record }">
                <template v-if="record.status === 0">
                  <div style="color: orange">
                    {{ "Waiting" }}
                  </div>
                </template>
                <template v-else-if="record.status === 1">
                  <div style="color: orange">
                    {{ "Running" }}
                  </div>
                </template>
                <template v-else-if="record.status === 2">
                  <div style="color: green">
                    {{ "Finish" }}
                  </div>
                </template>
                <template v-else>
                  <div style="color: orangered">
                    {{ "Warning" }}
                  </div>
                </template>
              </template>
              <!--  判题信息   -->
              <template #judgeInfoMessage="{ record }">
                <a-space wrap>
                  <!-- 使用 v-if-else 来判断消息内容，并设置标签颜色 -->
                  <template v-if="record.status === 0 || record.status === 1">
                    <a-spin />
                    <a-tag color="#86909c">
                      {{ "Loading" }}
                    </a-tag>
                  </template>
                  <a-tag
                    v-else-if="record.judgeInfo.message === 'Accepted'"
                    color="#7bc616"
                  >
                    {{ record.judgeInfo.message }}
                  </a-tag>
                  <a-tag
                    v-else-if="record.judgeInfo.message === 'Wrong Answer'"
                    color="red"
                  >
                    {{ record.judgeInfo.message }}
                  </a-tag>
                  <a-tag
                    v-else-if="record.judgeInfo.message === null"
                    color="#86909c"
                  >
                    {{ "undefined" }}
                  </a-tag>
                  <a-tag v-else color="#ff7d00">
                    {{ record.judgeInfo.message }}
                  </a-tag>
                </a-space>
              </template>
              <!--  运行时长    -->
              <template #judgeInfoTime="{ record }">
                {{ record.judgeInfo.time + " ms" }}
              </template>
              <!--  运行内存    -->
              <!--      <template #judgeInfoMemory="{ record }">-->
              <!--        {{ JSON.stringify(record.judgeInfo.memory) }}-->
              <!--      </template>-->

              <!--  提交时间   -->
              <template #createTime="{ record }">
                {{ moment(record.createTime).format("HH:mm:ss  MM-DD") }}
              </template>
              <template #optional="{ record }">
                <a-space>
                  <a-button
                    type="outline"
                    @click="getQuestionSubmitDatail(record)"
                    >查看
                  </a-button>
                </a-space>
              </template>
            </a-table>

            <!-- 弹出显示Viewer的弹窗 -->
            <a-modal
              v-model:visible="isModalVisible"
              title="详细记录"
              @ok="handleOk"
              @cancel="handleCancel"
              width="800px"
              :bodyStyle="{ maxHeight: '400px', overflowY: 'auto' }"
            >
              <!-- 使用 currentRecord 来显示数据 -->
              <div v-if="currentRecord && currentRecord.judgeInfo">
                <!--     输出判题信息 是否通过      -->
                <p
                  :style="{
                    color: currentRecord.judgeInfo.message
                      ? currentRecord.judgeInfo.message === 'Accepted'
                        ? 'green'
                        : 'red'
                      : 'red',
                  }"
                  style="font-size: 24px"
                >
                  &nbsp;&nbsp;
                  {{ currentRecord.judgeInfo.message || "Failed" }}
                </p>
              </div>
              <!-- 分割线 -->
              <a-divider dashed />
              <!--    输出程序执行状态     -->
              <div v-if="currentRecord && currentRecord.status">
                <template v-if="currentRecord.status === 0">
                  <div style="color: orange">
                    &nbsp;&nbsp;&nbsp;&nbsp; 运行状态: {{ "Waiting" }}
                  </div>
                </template>
                <template v-else-if="currentRecord.status === 1">
                  <div style="color: orange">
                    &nbsp;&nbsp;&nbsp;&nbsp; 运行状态: {{ "Running" }}
                  </div>
                </template>
                <template v-else-if="currentRecord.status === 2">
                  <div style="color: green">
                    &nbsp;&nbsp;&nbsp;&nbsp; 运行状态: {{ "Finish" }}
                  </div>
                </template>
                <template v-else>
                  <div style="color: orangered">
                    &nbsp;&nbsp;&nbsp;&nbsp; 运行状态: {{ "Warning" }}
                  </div>
                </template>
              </div>
              <!-- 输出运行时间 -->
              <div
                v-if="
                  currentRecord &&
                  currentRecord.judgeInfo &&
                  currentRecord.judgeInfo.time !== null
                "
                style="margin-top: 10px; color: #595959"
              >
                &nbsp;&nbsp;&nbsp;&nbsp; 运行时间:
                {{ currentRecord.judgeInfo.time + " ms" || "NA" }}
              </div>
              <!-- 分割线 -->
              <a-divider dashed />

              <!--   md展示代码   -->
              <MdViewer :value="modalContent" />
            </a-modal>
          </a-tab-pane>
          <a-tab-pane key="answer" title="答案" disabled>
            <template #title>答案</template>
            Content of Tab Panel 3
          </a-tab-pane>
        </a-tabs>
      </a-col>
      <!--   右侧   -->
      <a-col :md="12" :xs="24">
        <!--  下拉框选择 语言  -->
        <a-form class="form" :model="form" layout="inline">
          <a-form-item
            field="language"
            label="编程语言"
            style="min-width: 280px; margin-top: 10px; margin-bottom: 20px"
          >
            <a-select
              :style="{ width: '320px' }"
              v-model="form.language"
              placeholder="请选择语言"
            >
              <a-option>java</a-option>
              <a-option>cpp</a-option>
              <a-option>python</a-option>
              <a-option>html</a-option>
              <a-option disabled>go</a-option>
            </a-select>
          </a-form-item>
        </a-form>
        <!--   用户代码编辑器   -->
        <CodeEditor
          :value="form.code"
          :language="form.language"
          :handle-change="changeCode"
        />
        <a-button
          type="primary"
          style="min-width: 150px; min-height: 20px; margin-top: 15px"
          @click="doSubmit"
          >提交代码</a-button
        >
      </a-col>
    </a-row>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, withDefaults, defineProps } from "vue";
import {
  QuestionControllerService,
  QuestionSubmitAddRequest,
  QuestionVO,
} from "../../../generated";
import message from "@arco-design/web-vue/es/message";
import CodeEditor from "@/components/CodeEditor.vue";
import MdViewer from "@/components/MdViewer.vue";
import { useRoute, useRouter } from "vue-router";
import moment from "moment/moment";
import { useStore } from "vuex";

interface Props {
  id: string;
}

const store = useStore();
const route = useRoute();
const router = useRouter();
// 如果页面地址包含 question_submit，视为提交信息 详情页
const submitPage = route.path.includes("question_submit");
const question = ref<QuestionVO>();

const props = withDefaults(defineProps<Props>(), {
  id: () => "",
});

const dataList = ref([]);
const total = ref(0);
/**
 *  通过id获取 题目详细数据
 */
const loadData = async () => {
  if (submitPage) {
    console.log("提交记录");
    // changeCode("题目提交记录");
    form.value.code = "题目提交记录";
  }

  // 获取题目信息
  const res = await QuestionControllerService.getQuestionVoByIdUsingGet(
    props.id as any
  );
  if (res.code === 0) {
    question.value = res.data;
  } else {
    message.error("加载失败，" + res.message);
  }

  // 获取提交记录
  const recordRes =
    await QuestionControllerService.listQuestionSubmitVoByPageUsingPost({
      // 创建时间降序排序
      sortField: "createTime",
      sortOrder: "descend",
      questionId: question.value?.id,
      userId: store.state.user.loginUser.id,
    });

  console.log(recordRes);
  if (recordRes.code === 0) {
    dataList.value = recordRes.data.records; // 数据条数
    total.value = recordRes.data.total; // 数据总数
  } else {
    message.error("加载失败，" + res.message);
  }
};

/**
 *  页面加载时 请求数据
 */
onMounted(() => {
  loadData();
});

// 设置表单
const form = ref<QuestionSubmitAddRequest>({
  language: "java",
  code: "",
});

/**
 *  提交代码
 */
const doSubmit = async () => {
  if (!question.value?.id) {
    return;
  }

  const res = await QuestionControllerService.doQuestionSubmitUsingPost({
    ...form.value,
    questionId: question.value.id,
  });
  if (res.code === 0) {
    message.success("提交成功！");
  } else {
    message.error("提交失败！ " + res.message);
  }
};

/**
 *  向代码编辑器 响应用户输入代码 提交给父组件
 * @param value
 */
const changeCode = (value: string) => {
  form.value.code = value;
};

// 定义弹窗的显示与内容
const isModalVisible = ref(false);
const modalContent = ref(""); // 存放要显示的 Markdown 内容
const currentRecord = ref(null); // 用于弹窗时, 弹窗内元素使用(按钮响应初始化)

// 处理弹窗确认与关闭
const handleOk = () => {
  isModalVisible.value = false;
};

const handleCancel = () => {
  isModalVisible.value = false;
};

/**
 *  获取 一条提交记录并显示在弹窗中
 * @param record 提交记录
 */
const getQuestionSubmitDatail = (record: any) => {
  // 转存record 给弹窗
  currentRecord.value = record;
  // 弹窗显示
  isModalVisible.value = true;

  // 模拟获取记录的 Markdown 内容并赋值给弹窗
  modalContent.value = `### 代码记录
  \`\`\`${record.language}
  ${record.code}
  \`\`\`
  `;
};

/**
 *  获取 一条提交记录
 * @param value 题目提交id
 */
const getQuestionSubmitDatails = (value: number) => {
  return;
};

const columns = [
  {
    title: "通过",
    slotName: "judgeInfoMessage",
  },
  // {
  //   title: "提交号",
  //   dataIndex: "id",
  // },
  {
    title: "编程语言",
    dataIndex: "language",
  },
  {
    title: "运行状态",
    slotName: "status",
  },
  {
    title: "运行时间",
    slotName: "judgeInfoTime",
  },
  // {
  //   title: "运行内存",
  //   slotName: "judgeInfoMemory",
  // },
  {
    title: "提交时间",
    slotName: "createTime",
  },
  {
    title: "操作",
    slotName: "optional",
  },
];
</script>

<style scoped>
#questionDetailView {
  max-width: 1380px;
  margin: 0 auto;
}

#questionDetailView .arco-space-horizontal .arco-space-item {
  margin-bottom: 0 !important;
}
</style>

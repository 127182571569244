<template>
  <div id="UserLoginView">
    <h2 style="font-size: 30px; font-family: '楷体'">用户登录</h2>
    <a-form
      style="max-width: 480px; margin: 0 auto"
      label-align="left"
      auto-label-width
      :model="form"
      @submit="handleSubmit"
    >
      <a-form-item field="userAccount" tooltip="请输入账号!" label="账号">
        <a-input v-model="form.userAccount" placeholder="请输入账号" />
      </a-form-item>
      <a-form-item field="post" tooltip="请输入密码!" label="密码">
        <a-input-password
          v-model="form.userPassword"
          placeholder="请输入密码"
        />
      </a-form-item>
      <!--      <a-form-item field="isRead">-->
      <!--        <a-checkbox v-model="form.isRead"> I have read the manual</a-checkbox>-->
      <!--      </a-form-item>-->
      <a-form-item>
        <a-button type="primary" html-type="submit" style="width: 120px"
          >登录
        </a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script setup lang="ts">
import { reactive } from "vue";
import { UserControllerService, UserLoginRequest } from "../../../generated";
import message from "@arco-design/web-vue/es/message";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

const router = useRouter();
const store = useStore();

// 表单信息
const form = reactive({
  userAccount: "",
  userPassword: "",
} as UserLoginRequest); // 强制转换格式 用于请求

/**
 提交表单
 */
const handleSubmit = async () => {
  // 调用openApi生成的前端 axios，向后端方式请求 并介绍响应
  const res = await UserControllerService.userLoginUsingPost(form);

  if (res.code === 0) {
    alert("登录成功");

    // 前端请求获取当前用户信息, 更新用户状态
    await store.dispatch("user/getLoginUser");
    // 跳转主页, 且无法返回登录页
    router.push({
      path: "/",
      replace: true,
    });
  } else {
    // 登录失败
    message.error("登录失败，" + res.message);
  }
};
</script>

<style>
input[type="password"]::-ms-reveal {
  display: none;
}

input[type="password"]::-ms-clear {
  display: none;
}

input[type="password"]:-o-clear {
  display: none;
}
</style>

import { StoreOptions } from "vuex";
import ACCESS_ENUMS from "@/access/accessEnum";
import { UserControllerService } from "../../generated";

export default {
  namespaced: true,
  // initial state 存储状态信息
  state: () => ({
    loginUser: {
      userName: "未登录",
      // userRole: null,
      // role: ACCESS_ENUMS.NOT_LOGIN,   未登录过默认权限为空
    },
  }),
  // actions 执行异步操作 并触发mutations更改
  actions: {
    async getLoginUser({ commit, state }, payload) {
      // 测试使用，交互时需要修改成 从远程获取登录信息
      // commit("updateUser", payload);

      // 向后端发生请求 获取登录用户的信息
      const res = await UserControllerService.getLoginUserUsingGet();
      if (res.code === 0) {
        // 用户已登录
        commit("updateUser", res.data);
        console.log("用户已登录");
      } else {
        // 未登录
        commit("updateUser", {
          ...state.loginUser,
          userRole: ACCESS_ENUMS.NOT_LOGIN, // 修改权限为未登录
        });
        console.log("用户未登录，状态无法更新！");
      }
    },
  },
  // mutations 更新变量(增删改查)
  mutations: {
    updateUser(state, payload) {
      state.loginUser = payload;
    },
  },
} as StoreOptions<any>;

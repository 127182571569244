<template>
  <div id="app">
    <!--  用户路径 导向默认  -->
    <template v-if="route.path.startsWith('/user')">
      <router-view />
    </template>
    <!--  其他导向通用导航栏模板  -->
    <template v-else>
      <BasicLayout />
    </template>
  </div>
</template>

<style>
#app {
}
</style>

<script setup lang="ts">
import BasicLayout from "@/layouts/BasicLayout.vue";
// import index from "@/access/index";
import { useRoute } from "vue-router";
import { onMounted } from "vue";

const route = useRoute();

/**
 *  预留 全局初始化函数
 */
const doInit = () => {
  console.log("全局初始化函数");
};
onMounted(() => {
  doInit();
});
</script>

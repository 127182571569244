<template>
  <a-row id="globalHeader" class="grid-demo" align="center" :wrap="false">
    <a-col flex="auto">
      <!--  @menu-item-click 绑定菜单响应事件
          selected-keys 绑定当前选中的菜单项(高亮显示) 路由更新 -->
      <a-menu
        mode="horizontal"
        :selected-keys="selectedKeys"
        @menu-item-click="doMenuClick"
      >
        <a-menu-item
          key="0"
          :style="{ padding: 0, marginRight: '38px' }"
          disabled
        >
          <div class="title-bar">
            <img class="logo" src="../assets/logo.png" />
            <div class="title">CxCS OJ</div>
          </div>
        </a-menu-item>
        <!--   循环 使用路由访问不同 tittle，动态页面    -->
        <a-menu-item v-for="item in visibleRoutes" :key="item.path">
          {{ item.name }}
        </a-menu-item>
      </a-menu>
    </a-col>
    <a-col flex="100px">
      <div>
        <!--   输出vuex定义的全局用户变量 用户名    -->
        <template v-if="store.state.user?.loginUser?.userName === '未登录'">
          <a-space>
            <a-button type="primary" status="success" @click="tologin"
              >登录</a-button
            >
            <a-button type="primary" status="success" @click="toregister"
              >注册</a-button
            >
          </a-space>
        </template>
        <template v-else>
          <a-space>
            <a-button type="outline">{{
              store.state.user?.loginUser?.userName
            }}</a-button>
            <a-button type="text" style="color: black" @click="tologout"
              >退出登录</a-button
            >
          </a-space>
        </template>
      </div>
    </a-col>
  </a-row>
</template>

<script setup lang="ts">
import { routes } from "../router/routes";
import { useRouter } from "vue-router";
import { computed, ref } from "vue";
import { useStore } from "vuex";
import checkAccess from "@/access/checkAccess";
import ACCESS_ENUMS from "@/access/accessEnum";
import { Question, UserControllerService } from "../../generated";
import message from "@arco-design/web-vue/es/message"; // 导入路由

const router = useRouter(); // 该组件作为路由的跳转
const store = useStore();
// const loginUser; // 获取用户信息

// 菜单栏显隐的 路由过滤 用于vuefor循环判断
// 使用 computed 函数 当用户权限变更时(如登录) 动态更新菜单
const visibleRoutes = computed(() => {
  return routes.filter((item, index) => {
    if (item.meta?.hideInMenu) {
      return false; // filter过滤 隐藏菜单
    }

    // todo 判断用户有无权限(使用checkAccess 检验方法)
    if (!checkAccess(store.state.user.loginUser, item.meta?.access as string)) {
      // access权限强转为string
      return false;
    }

    return true; // 放行
  });
});

const selectedKeys = ref(["/"]); // 当前选中菜单项，默认主页
// 当路由跳转后，更新选中的菜单项
router.afterEach((to, form, failure) => {
  selectedKeys.value = [to.path]; // 更新为要跳转到的路径
});

const doMenuClick = (key: string) => {
  // 菜单点击响应 路径跳转
  router.push({
    path: key, // 菜单跳转路径
  });
};

// 测试修改用户信息 (模拟自动登录) 每次刷新三秒后更新
setTimeout(() => {
  // 调用user.ts中action定义的getloginUser方法 传入参数更新
  store.dispatch("user/getLoginUser", {
    // userName: "管理员：CxCS",
    // userRole: ACCESS_ENUMS.ADMIN,
  });
}, 3000);

/**
 *  跳转登录页面
 */
const tologin = () => {
  // 路由跳转 get传id
  router.push({
    path: `/user/login`,
  });
};

/**
 *  跳转注册页面
 */
const toregister = () => {
  // 路由跳转 get传id
  router.push({
    path: `/user/register`,
  });
};

/**
 *  用户注销
 */
const tologout = () => {
  const res = UserControllerService.userLogoutUsingPost();
  // 刷新页面
  window.location.reload();
};

console.log(store.state.user.loginUser);
</script>

<style scoped>
.title-bar {
  display: flex;
  align-items: center;
}

.logo {
  height: 40px;
}

.title {
  font-size: 18px;
  color: #444;
  margin-left: 20px;
}
</style>

<template>
  <div id="UserRegisterView">
    <h2 style="font-size: 30px; font-family: '楷体'">用户注册</h2>
    <a-form
      style="max-width: 480px; margin: 0 auto"
      label-align="left"
      auto-label-width
      :model="form"
      @submit="handleSubmit"
    >
      <a-form-item field="userAccount" tooltip="请输入账号!" label="账号">
        <a-input v-model="form.userAccount" placeholder="请输入账号" />
      </a-form-item>
      <a-form-item field="post" tooltip="请输入密码!" label="密码">
        <a-input-password
          v-model="form.userPassword"
          placeholder="请输入密码"
        />
      </a-form-item>
      <a-form-item field="post" tooltip="请确认密码!" label="确认密码">
        <a-input-password
          v-model="form.checkPassword"
          placeholder="请再次确认密码"
        />
      </a-form-item>
      <a-form-item>
        <a-button type="primary" html-type="submit" style="width: 120px"
          >注册账号
        </a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script setup lang="ts">
import { reactive } from "vue";
import { UserControllerService, UserRegisterRequest } from "../../../generated";
import message from "@arco-design/web-vue/es/message";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

const router = useRouter();
const store = useStore();

// 表单信息
const form = reactive({
  userAccount: "",
  userPassword: "",
  checkPassword: "",
} as UserRegisterRequest); // 强制转换格式 用于请求

/**
 提交表单
 */
const handleSubmit = async () => {
  if (form.userPassword !== form.checkPassword) {
    message.error("两次输入密码不同!");
  } else {
    // 调用openApi生成的前端 axios，向后端方式请求 并介绍响应
    const res = await UserControllerService.userRegisterUsingPost(form);

    if (res.code === 0) {
      alert("注册成功");

      // 跳转主页, 且无法返回登录页
      router.push({
        path: "/user/login",
        replace: true,
      });
    } else {
      // 登录失败
      message.error("注册失败，" + res.message);
    }
  }
};
</script>

<template>
  <div class="container">
    <div class="home">
      <div class="content-box">
        <h1>CxCS Online Judge</h1>

        <p>
          CxCS算法在线测评系统，简称 CxCS OJ是一个在线的判题系统。<br />
          <span style="font-weight: bolder"
            >[个人项目, 麻烦轻点使用, 首次访问需等待服务器启动实例]</span
          >
        </p>
        <p>
          管理员可以上传题目(完善测试样例) 管理题目<br />
          用户可以在在线提交程序多种程序（如Java）源代码，
          系统使用独立的代码沙箱对(暂时自主实现Java)源代码进行编译和执行，并通过预先创建的测试用例来检验代码。<br />
          用户还可以查看历史提交记录 判题详细等
        </p>
        <p>Java代码沙箱独立部署运行在其他服务器, 可供其他开发者调用</p>
        <p style="color: #007bff">
          账户: cxcs<br />
          密码: 12345678
        </p>

        <div class="contact-section">
          <h2>
            联系开发者
            <a
              target="_blank"
              href="http://mail.qq.com/cgi-bin/qm_share?t=qm_mailme&email=SXh8eXl6en18e3AJODhnKiYk"
              style="text-decoration: none"
              ><img
                src="http://rescdn.qqmail.com/zh_CN/htmledition/images/function/qm_open/ico_mailme_02.png"
            /></a>
          </h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style>
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

.container {
  margin-top: 70px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-height: 120vh;
  flex-direction: column;
}

.content-box {
  max-width: 620px;
  margin: 10px auto;
  background-color: #f1f5fd;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h1 {
  color: #333;
  margin-bottom: 30px;
}

h2 {
  font-family: "楷体";
}

p {
  font-size: 16px;
  line-height: 1.5;
  color: #666;
  margin-bottom: 30px;
}

.contact-button {
  display: block;
  width: 150px;
  height: 40px;
  background-color: #007bff;
  color: white;
  text-align: center;
  line-height: 40px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.contact-button:hover {
  background-color: #0056b3;
}
</style>

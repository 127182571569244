// 全局权限管理 总的 路由拦截校验
import store from "@/store";
import router from "@/router";
import ACCESS_ENUMS from "@/access/accessEnum";
import checkAccess from "@/access/checkAccess";
import message from "@arco-design/web-vue/es/message";
import { useRouter } from "vue-router";

// const route = useRouter();
// 监听路由信息
router.beforeEach(async (to, from, next) => {
  let loginUser = store.state.user.loginUser;
  // user或权限为空(未登录过) 执行自动登录
  if (!loginUser || !loginUser.userRole) {
    // await 是为了在用户登陆成功后，再执行后续代码
    await store.dispatch("user/getLoginUser"); // 执行user.ts 自动登录
    loginUser = store.state.user.loginUser;
  }

  const needAccess = to.meta?.access ?? ACCESS_ENUMS.NOT_LOGIN; // 获取访问该页面需要的权限
  // console.log("当前页面所需权限：" + needAccess);
  // 需要登录权限
  if (needAccess !== ACCESS_ENUMS.NOT_LOGIN) {
    if (
      !loginUser ||
      !loginUser.userRole ||
      loginUser.userRole === ACCESS_ENUMS.NOT_LOGIN
    ) {
      // 未登录
      useRouter().push({
        path: `/user/login`,
      }); // 直接跳转登录页
      message.error("未登录或没有权限！");
      return;
    }
    // 调用checkAccess 函数进行权限校验, 传入登录用户信息和所需权限
    if (!checkAccess(loginUser, needAccess as string)) {
      next("/NoAuth"); // 跳转无权限页
      return;
    }
  }

  next(); // 有权限， 继续访问
});

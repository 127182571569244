<template>
  <div id="questionsSubmitView">
    <!--  搜索框表单  -->
    <a-form class="search" :model="searchParas" layout="inline">
      <a-form-item field="questionId" label="题号" style="min-width: 280px">
        <a-input v-model="searchParas.questionId" placeholder="请输入题号" />
      </a-form-item>
      <a-form-item field="language" label="编程语言" style="min-width: 280px">
        <a-select
          :style="{ width: '320px' }"
          v-model="searchParas.language"
          placeholder="请选择语言"
        >
          <a-option>java</a-option>
          <a-option>cpp</a-option>
          <a-option>python</a-option>
          <a-option>html</a-option>
          <a-option disabled>go</a-option>
        </a-select>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" @click="doSearch">搜索</a-button>
      </a-form-item>
    </a-form>
    <!--  题目提交记录列表  -->
    <a-table
      :columns="columns"
      :data="dataList"
      :pagination="{
        showTotal: true,
        pageSize: searchParas.pageSize,
        current: searchParas.current,
        total,
      }"
      @page-change="onPageChange"
      style="margin: 0 20px"
    >
      <!--   题目   -->
      <template #questionTitle="{ record }">
        {{ record.questionVO.title }}
      </template>
      <!--  运行状态 0 - 等待中、1 - 运行中、2 - 成功、3 - 失败 -->
      <template #status="{ record }">
        <template v-if="record.status === 0">
          <div style="color: orange">
            {{ "Waiting" }}
          </div>
        </template>
        <template v-else-if="record.status === 1">
          <div style="color: orange">
            {{ "Running" }}
          </div>
        </template>
        <template v-else-if="record.status === 2">
          <div style="color: green">
            {{ "Finish" }}
          </div>
        </template>
        <template v-else>
          <div style="color: orangered">
            {{ "Warning" }}
          </div>
        </template>
      </template>
      <!--  判题信息 -- 通过   -->
      <template #judgeInfoMessage="{ record }">
        <a-space wrap>
          <!-- 使用 v-if-else 来判断消息内容，并设置标签颜色 -->
          <template v-if="record.status === 0 || record.status === 1">
            <a-spin />
            <a-tag color="#86909c">
              {{ "Loading" }}
            </a-tag>
          </template>
          <a-tag
            v-else-if="record.judgeInfo.message === 'Accepted'"
            color="#7bc616"
          >
            {{ record.judgeInfo.message }}
          </a-tag>
          <a-tag
            v-else-if="record.judgeInfo.message === 'Wrong Answer'"
            color="red"
          >
            {{ record.judgeInfo.message }}
          </a-tag>
          <a-tag v-else-if="record.judgeInfo.message === null" color="#86909c">
            {{ "undefined" }}
          </a-tag>
          <a-tag v-else color="#ff7d00">
            {{ record.judgeInfo.message }}
          </a-tag>
        </a-space>
      </template>
      <!--  运行时长    -->
      <template #judgeInfoTime="{ record }">
        {{ record.judgeInfo.time + " ms" }}
      </template>
      <!--  运行内存    -->
      <!--      <template #judgeInfoMemory="{ record }">-->
      <!--        {{ JSON.stringify(record.judgeInfo.memory) }}-->
      <!--      </template>-->

      <!--   提交用户（VO脱敏）   -->
      <template #userName="{ record }">
        {{ record.userVO.userName }}
      </template>
      <!--  提交时间   -->
      <template #createTime="{ record }">
        {{ moment(record.createTime).format("YYYY-MM-DD") }}
      </template>
      <template #optional="{ record }">
        <a-space>
          <a-button type="primary" @click="toQuestionPage(record.questionId)"
            >查看
          </a-button>
        </a-space>
      </template>
    </a-table>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, watchEffect } from "vue";
import {
  Question,
  QuestionControllerService,
  QuestionQueryRequest,
  QuestionSubmitQueryRequest,
} from "../../../generated";
import message from "@arco-design/web-vue/es/message";
import { useRouter } from "vue-router";
import moment from "moment";

const router = useRouter();

const show = ref(true);

const dataList = ref([]);
const total = ref(0);
const searchParas = ref<QuestionSubmitQueryRequest>({
  // 查询条件
  questionId: undefined,
  language: undefined,
  pageSize: 10, // 每页题目数
  current: 1,
});

/**
 *  分页获取 题目数据
 */
const loadData = async () => {
  const res =
    await QuestionControllerService.listQuestionSubmitVoByPageUsingPost({
      // 创建时间降序排序
      ...searchParas.value,
      sortField: "createTime",
      sortOrder: "descend",
    });
  console.log(res.data);
  // 查询结果为0
  if (res.code === 0) {
    dataList.value = res.data.records; // 数据条数
    total.value = res.data.total; // 数据总数
  } else {
    message.error("加载失败，" + res.message);
  }
};

/**
 *  页面加载时 请求数据
 */
onMounted(() => {
  loadData();
});

const columns = [
  {
    title: "提交号",
    dataIndex: "id",
  },
  {
    title: "题目",
    slotName: "questionTitle",
  },
  {
    title: "编程语言",
    dataIndex: "language",
  },
  {
    title: "运行状态",
    slotName: "status",
  },
  {
    title: "通过",
    slotName: "judgeInfoMessage",
  },
  {
    title: "运行时间",
    slotName: "judgeInfoTime",
  },
  // {
  //   title: "运行内存",
  //   slotName: "judgeInfoMemory",
  // },
  {
    title: "提交用户",
    slotName: "userName",
  },
  {
    title: "提交时间",
    slotName: "createTime",
  },
  {
    title: "操作",
    slotName: "optional",
  },
];

/**
 * 监听函数loadData
 *  若函数中有变量改变，程序执行该函数
 *   用于分页，当searchParas改变时，重新加载页面
 */
watchEffect(() => {
  loadData();
});

// 分页时更新页号
const onPageChange = (page: number) => {
  searchParas.value = {
    ...searchParas.value,
    current: page,
  };
};

/**
 *  跳转题目详情页
 * @param questionId
 */
const toQuestionPage = (questionId: number) => {
  // 路由跳转 get传id
  router.push({
    path: `/detail/question_submit/${questionId}`,
  });
};

/**
 *  题目模糊搜索
 *    搜索是对search修改，提交时 触发自动刷新
 */
const doSearch = () => {
  searchParas.value = {
    ...searchParas.value,
    current: 1, // 页号恢复到第一页
  };
};
</script>

<style scoped>
#questionsSubmitView {
  max-width: 1280px;
  margin: 0 auto;
}

.search {
  margin: 15px 50px;
}
</style>

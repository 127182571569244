import { RouteRecordRaw } from "vue-router";
import HomeView from "@/views/HomeView.vue";
import AdminView from "@/views/AdminView.vue";
import NoAuthView from "@/views/NoAuthView.vue";
import ACCESS_ENUMS from "@/access/accessEnum";
import UserLayout from "@/layouts/UserLayout.vue";
import UserLoginView from "@/views/user/UserLoginView.vue";
import UserRegisterView from "@/views/user/UserRegisterView.vue";
import AddQuestionView from "@/views/question/AddQuestionView.vue";
import ManageQuestionView from "@/views/question/ManageQuestionView.vue";
import UpdateQuestionView from "@/views/question/UpdateQuestionView.vue";
import QuestionsView from "@/views/question/QuestionsView.vue";
import QuestionDatailView from "@/views/question/QuestionDatailView.vue";
import QuestionsSubmitView from "@/views/question/QuestionsSubmitView.vue";

// 路由定义跳转路径访问
export const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "主页",
    component: HomeView,
  },
  {
    path: "/questions",
    name: "题目列表",
    component: QuestionsView,
  },
  {
    path: "/detail/question/:id",
    name: "题目详情",
    component: QuestionDatailView,
    props: true, // 路径访问 动态参数路由
    // 权限
    meta: {
      access: ACCESS_ENUMS.USER,
      hideInMenu: true, // 隐藏
    },
  },
  {
    path: "/questions_submit",
    name: "提交记录",
    component: QuestionsSubmitView,
    props: true, // 路径访问 动态参数路由
    // 权限
    meta: {
      access: ACCESS_ENUMS.USER,
      // hideInMenu: true, // 隐藏
    },
  },
  {
    path: "/detail/question_submit/:id",
    name: "提交记录详情",
    component: QuestionDatailView,
    props: true, // 路径访问 动态参数路由
    // 权限
    meta: {
      access: ACCESS_ENUMS.USER,
      hideInMenu: true, // 隐藏
    },
  },
  {
    path: "/add/question",
    name: "创建题目",
    component: AddQuestionView,
    // 权限
    meta: {
      access: ACCESS_ENUMS.ADMIN,
    },
  },
  {
    path: "/update/question",
    name: "更新题目",
    component: AddQuestionView,
    // 权限
    meta: {
      access: ACCESS_ENUMS.ADMIN,
      hideInMenu: true, // 隐藏
    },
  },
  {
    path: "/manage/question",
    name: "管理题目",
    component: ManageQuestionView,
    // 权限
    meta: {
      access: ACCESS_ENUMS.ADMIN,
    },
  },
  {
    path: "/NoAuth",
    name: "404无权限",
    component: NoAuthView,
    meta: {
      hideInMenu: true, // 隐藏
    },
  },
  {
    path: "/user",
    name: "用户",
    component: UserLayout,
    meta: {
      hideInMenu: true, // 隐藏
    },
    children: [
      {
        path: "/user/login",
        name: "用户登录",
        component: UserLoginView,
      },
      {
        path: "/user/register",
        name: "用户注册",
        component: UserRegisterView,
      },
    ],
  },
];
